import { Component, Input } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { Link, NavTabItem } from '@Shared/interface/interface';

@Component({
  selector: 'ds-page-title-with-tabs',
  templateUrl: './page-title-with-tabs.component.html',
  styleUrls: ['./page-title-with-tabs.component.scss'],
})
export class PageTitleWithTabsComponent {
  @Input() brand: BRAND | undefined = undefined;
  @Input() description?: string;
  @Input() figmaLinks?: Link[];
  @Input() tabs!: NavTabItem[];
  @Input() title!: string;
}

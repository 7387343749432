<div
  *ngIf="link || (!link && !isLoadingData)"
  class="installation"
  [style.--brand-color]="getBrandColour()"
  [ngClass]="{ 'icon-generic': !brand }">
  <div class="head">
    <svg-icon
      src="./resources/icons/{{ iconName }}.svg"
      class="svg"
      svgClass="icon icon-lg"></svg-icon>
  </div>

  <div class="tail" [ngClass]="{ 'has-link': link }">
    <ng-container *ngIf="link">
      <!-- Need to update this to routerLink -->
      <a *ngIf="!link.isExternalLink" [attr.href]="link.url">{{ link.label }}</a>

      <a *ngIf="link.isExternalLink" [href]="link.url" target="_blank" rel="noopener noreferrer">
        {{ link.label }}<span class="u-type-visually-hidden"> Opens in a new window</span>
      </a>

      <svg-icon
        src="./resources/icons/external-link.svg"
        class="svg"
        svgClass="icon icon-sm"></svg-icon>
    </ng-container>

    <ng-container *ngIf="!link">
      <pre>
        <code class="package-link">{{ publishedLink }} </code>
        <code class="package-version">Published on {{publishedDate}}</code>
      </pre>

      <button aria-label="copy" ngxClipboard [cbContent]="text">
        <svg-icon src="./resources/icons/copy.svg" svgClass="icon icon-sm"></svg-icon>
      </button>
    </ng-container>
  </div>
</div>
<div *ngIf="!link && isLoadingData" class="loader">
  <svg-icon src="./resources/icons/loading.svg" class="svg"></svg-icon>
</div>

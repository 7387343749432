/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable class-methods-use-this */
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { Link } from '@Shared/interface/interface';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PackageDetails } from './installation.constants';

@Component({
  selector: 'ds-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent implements OnInit, OnDestroy {
  @Input() brand?: BRAND | null;
  @Input() iconName = 'design';
  @Input() link?: Link | null;
  @Input() text?: string;

  subscription$: Subscription = new Subscription();
  packageName = '';
  publishedDate: string | null = '';
  publishedLink = '';
  isLoadingData = true;

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  ngOnInit() {
    if (this.text) {
      this.getLinkDetails(this.text);
    }
  }

  getLinkDetails = (text: string) => {
    this.packageName = text.substring(text.lastIndexOf('/') + 1);
    this.subscription$ = this.getPackage(this.packageName).subscribe({
      next: (data) => {
        if (data) {
          this.publishedLink = `${text}@${data[0].name}`;
          this.publishedDate = this.datePipe.transform(
            new Date(data[0].updated_at),
            'MMM dd, YYYY'
          );
          this.isLoadingData = false;
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoadingData = false;
      },
    });
  };

  getPackage(packageName: string): Observable<PackageDetails[]> {
    const url = `https://api.github.com/orgs/woolworthslimited/packages/npm/${packageName}/versions?per_page=1`;
    const { token } = environment;

    const headers = new HttpHeaders({
      Accept: 'application/vnd.github+json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<PackageDetails[]>(url, { headers });
  }

  getBrandColour(): string {
    const color = this.brand ? `--${this.brand}-installation` : '--core-installation';

    return getComputedStyle(document.documentElement).getPropertyValue(`${color}`);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}

<nav mat-tab-nav-bar disableRipple="true" [ngClass]="brand + '-theme'">
  <a
    #navLink
    mat-tab-link
    *ngFor="let item of navItems"
    [routerLink]="item.link"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive">
    {{ item.label }}
  </a>
</nav>

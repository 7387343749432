<h1 *ngIf="!description" class="headline-lg u-responsive-spacer-md">{{ title }}</h1>

<div *ngIf="description" class="wrapper" [ngClass]="brand + ''">
  <h1 class="headline-lg spacer spacer-size-lg">{{ title }}</h1>

  <p>{{ description }}</p>

  <ul *ngIf="figmaLinks" class="figma-links">
    <li *ngFor="let link of figmaLinks">
      <svg-icon src="./resources/icons/figma.svg" class="svg"></svg-icon>
      <ds-link></ds-link>
      <ds-link
        [isExternalLink]="link.isExternalLink"
        [label]="link.label"
        [attr.ariaLabel]="link.ariaLabel"
        [url]="link.url"></ds-link>
    </li>
  </ul>
</div>

<ds-nav-tabs [navItems]="tabs" [brand]="brand"></ds-nav-tabs>

<ds-side-nav-template>
  <div template-slot-content></div>
</ds-side-nav-template>

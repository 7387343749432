/* eslint-disable class-methods-use-this */
import { Component, Input } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { Link } from '@Shared/layout/shared/interface/layout.interface';

import { DEFAULT_CONFIG } from './nav-list.constants';

@Component({
  selector: 'ds-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent {
  @Input() brand: BRAND = DEFAULT_CONFIG.brand;
  @Input() isSmall: boolean = DEFAULT_CONFIG.isSmall;
  @Input() links: Link[] = DEFAULT_CONFIG.links;

  getBrandColour() {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${this.brand}`);
  }
}

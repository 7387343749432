<div
  class="content-section"
  [ngClass]="{
    'has-gradient': hasGradient,
    'remove-bottom-padding': removeBottomPadding,
    'remove-top-padding': removeTopPadding,
    'in-side-nav-layout': inSideNavLayout
  }">
  <div [ngClass]="{ container: hasGradient }">
    <div *ngIf="heading || description" class="header">
      <h1 *ngIf="hasPageTitle && heading" class="headline-lg u-responsive-spacer-md">
        {{ heading }}
      </h1>
      <h2 *ngIf="!hasPageTitle && heading" class="headline-md">{{ heading }}</h2>
      <p *ngIf="description">{{ description }}</p>
    </div>
    <ng-content></ng-content>
  </div>
</div>

<article class="card">
  <img
    src="./resources/images/cards/{{ imgFilename }}"
    alt=""
    width="1180"
    height="584"
    loading="lazy" />
  <div class="content">
    <h3 *ngIf="heading" class="headline-sm">{{ heading }}</h3>
    <p>{{ description }}</p>
  </div>
  <a routerLink="{{ routerUrl }}" class="learn-more-link">
    <span>Learn more</span>
  </a>
</article>

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';

@Injectable({ providedIn: 'root' })
export class AppService {
  activeBrand: BRAND | null = null;
  constructor(@Optional() @Inject(DOCUMENT) private readonly documentRef: Document) {}

  public toggleBodyOverflow(disableOverflow: boolean) {
    if (disableOverflow) {
      this.documentRef.body.style.overflowY = 'hidden';
    } else {
      this.documentRef.body.style.overflowY = 'initial';
    }
  }

  setActiveBrand(brand: BRAND | null) {
    this.activeBrand = brand;
  }

  getActiveBrand() {
    return this.activeBrand;
  }
}

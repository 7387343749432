export const DEFAULT_PAGE_TITLE = 'Core Design System';

export enum BREAKPOINTS {
  xxs = '20em',
  xs = '25.875em',
  sm = '48em',
  md = '64em',
  lg = '80em',
  xl = '90em',
  xxl = '105em',
}

export enum BRAND {
  BIGW = 'bigw',
  EVERYDAY = 'everyday',
  WOOLWORTHS = 'woolworths',
}

export const EMAIL_ADDRESS = 'core@woolworths.com.au';

export const VERSION_MESSAGES = {
  message: 'This is a beta release currently in its testing phase and open for feedback',
  tag: 'v1.0.0-beta.9',
};

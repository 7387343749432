<a
  *ngIf="isExternalLink"
  [ngClass]="{ 'is-disabled': isDisabled }"
  [href]="url"
  target="_blank"
  rel="noopener noreferrer">
  {{ label }}<span class="u-type-visually-hidden"> Opens in a new window</span>
  <svg-icon
    *ngIf="!isDisabled"
    src="./resources/icons/external-link-dark.svg"
    class="svg"></svg-icon>
</a>

<a *ngIf="!isExternalLink" [ngClass]="{ 'is-disabled': isDisabled }" [routerLink]="url">
  {{ label }}
</a>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss'],
})
export class ContentSectionComponent {
  @Input() description = '';
  @Input() hasGradient = false;
  @Input() heading = '';
  @Input() hasPageTitle = false;
  @Input() inSideNavLayout = false;
  @Input() removeBottomPadding = false;
  @Input() removeTopPadding = false;
}

import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { NavTabItem } from '@Shared/interface/interface';

@Component({
  selector: 'ds-nav-tabs',
  templateUrl: './nav-tabs.component.html',
})
export class NavTabsComponent implements AfterViewInit {
  @ViewChildren('navLink') navLinks!: QueryList<HTMLElement>;

  @Input() brand: BRAND | undefined = undefined;
  @Input() navItems!: NavTabItem[];

  constructor(private focusMonitor: FocusMonitor) {}

  ngAfterViewInit() {
    this.navLinks.forEach((item: HTMLElement) => {
      this.focusMonitor.stopMonitoring(item);
    });
  }
}

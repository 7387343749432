import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Event, NavigationEnd, Router } from '@angular/router';
import { BRAND, DEFAULT_PAGE_TITLE } from '@Shared/constants/constants';
import { GoogleAnalyticsService } from '@Shared/services/analytics.service';

import { AppService } from './app.service';

@Component({
  selector: 'ds-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  brand: BRAND | null = null;
  enableSideNavToggle = false;
  loginDisplay = false;
  title = DEFAULT_PAGE_TITLE;

  constructor(
    private analyticsService: GoogleAnalyticsService,
    private appService: AppService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.subscribeActivationEnd();
  }

  subscribeActivationEnd() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd && !event.snapshot.children.length) {
        this.brand = <BRAND | null>event.snapshot.data.brand;
        this.appService.setActiveBrand(this.brand);
        this.enableSideNavToggle = event.snapshot.data.hasSideNav as boolean;
        this.titleService.setTitle(event.snapshot.data.title as string);
      }

      if (event instanceof NavigationEnd) {
        this.analyticsService.pageView(event.urlAfterRedirects);
      }
    });
  }
}

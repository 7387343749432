<div id="table-of-content-heading" class="title-sm spacer spacer-size-2xs" aria-hidden="true">
  Page content
</div>
<nav aria-labelledby="table-of-content-heading">
  <ul class="small" [style.--brand]="getBrandColour()">
    <li *ngFor="let item of navItemsWithSingleActiveElement()">
      <a routerLink="." [fragment]="item.anchor" [ngClass]="{ 'is-active': item.visible }">
        {{ item.label }}
      </a>
    </li>
  </ul>
</nav>

import { Component, Input, OnInit } from '@angular/core';
import { LazyImage } from '@Shared/interface/interface';
import { HeadingLevel } from '@Shared/types/types';

@Component({
  selector: 'ds-layout-card',
  templateUrl: './layout-card.component.html',
  styleUrls: ['./layout-card.component.scss'],
})
export class LayoutCardComponent implements OnInit {
  @Input() description?: string;
  @Input() heading? = '';
  @Input() headingLevel?: HeadingLevel = HeadingLevel.H4;
  @Input() img?: LazyImage;
  @Input() imgLast? = false;

  isH3 = false;

  ngOnInit() {
    this.isH3 = this.headingLevel === HeadingLevel.H3;
  }
}

import { Injectable } from '@angular/core';

declare global {
  interface Window {
    GAKey: string;
    gtag: (...args: any[]) => void;
  }
}
/* eslint class-methods-use-this:
  ["error", { "exceptMethods": ["eventEmitter", "pageView"] }] */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: number | null = null
  ): void {
    window.gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    });
  }

  pageView(page_path: string) {
    window.gtag('config', window.GAKey, { page_path });
  }
}

import { IsActiveMatchOptions } from '@angular/router';
import { BRAND } from '@Shared/constants/constants';

export const DEFAULT_CONFIG = {
  brand: BRAND.WOOLWORTHS,
  isSmall: false,
  links: [],
  heading: '',
};

export const ROUTER_MATCH_OPTIONS: IsActiveMatchOptions = {
  queryParams: 'ignored',
  matrixParams: 'exact',
  paths: 'subset',
  fragment: 'exact',
};

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-line-break',
  templateUrl: './line-break.component.html',
  styleUrls: ['./line-break.component.scss'],
})
export class LineBreakComponent {
  @Input() type = 'solid';
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

/*
 * USAGE:
 * <div dsObserveVisibility (visible)="visibilityChange($event)"></div>
 */

@Directive({
  selector: '[dsObserveVisibility]',
})
export class ObserveVisibilityDirective implements OnDestroy, AfterViewInit {
  @Output() visible = new EventEmitter<boolean>();

  private observer: IntersectionObserver | undefined;
  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    // TODO: https://woolworthsdigital.atlassian.net/browse/NGDEDS-532, take threshold as input
    const options = { rootMargin: '0px', threshold: 0.01 };

    this.observer = new IntersectionObserver(([entry]) => {
      this.visible.emit(entry.isIntersecting);
    }, options);

    this.observer.observe(this.element.nativeElement as Element);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

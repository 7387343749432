import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { CalloutComponent } from '@Components/callout/callout.component';
import { CardComponent } from '@Components/card/card.component';
import { ContentSectionComponent } from '@Components/content-section/content-section.component';
import { FollowFoundationGuidelinesComponent } from '@Components/follow-foundation-guidelines/follow-foundation-guidelines.component';
import { GettingSupportComponent } from '@Components/getting-support/getting-support.component';
import { HeroBannerComponent } from '@Components/hero-banner/hero-banner.component';
import { ImageComponent } from '@Components/image/image.component';
import { InstallationComponent } from '@Components/installation/installation.component';
import { LayoutCardComponent } from '@Components/layout-card/layout-card.component';
import { LineBreakComponent } from '@Components/line-break/line-break.component';
import { LinkComponent } from '@Components/link/link.component';
import { NavCollapsibleComponent } from '@Components/nav-collapsible/nav-collapsible.component';
import { NavListComponent } from '@Components/nav-list/nav-list.component';
import { NavTabsComponent } from '@Components/nav-tabs/nav-tabs.component';
import { PageTitleWithTabsComponent } from '@Components/page-title-with-tabs/page-title-with-tabs.component';
import { PanelComponent } from '@Components/panel/panel.component';
import { SideNavTemplateComponent } from '@Components/side-nav-template/side-nav-template.component';
import { TableOfContentComponent } from '@Components/table-of-content/table-of-content.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ClipboardModule } from 'ngx-clipboard';

import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';

@NgModule({
  declarations: [
    CardComponent,
    CalloutComponent,
    ContentSectionComponent,
    FollowFoundationGuidelinesComponent,
    GettingSupportComponent,
    HeroBannerComponent,
    ImageComponent,
    InstallationComponent,
    LayoutCardComponent,
    LineBreakComponent,
    LinkComponent,
    NavCollapsibleComponent,
    NavListComponent,
    NavTabsComponent,
    PageTitleWithTabsComponent,
    PanelComponent,
    SideNavTemplateComponent,
    TableOfContentComponent,
    ObserveVisibilityDirective,
  ],
  exports: [
    CardComponent,
    CalloutComponent,
    ContentSectionComponent,
    FollowFoundationGuidelinesComponent,
    GettingSupportComponent,
    HeroBannerComponent,
    ImageComponent,
    InstallationComponent,
    LayoutCardComponent,
    LineBreakComponent,
    LinkComponent,
    NavCollapsibleComponent,
    NavListComponent,
    NavTabsComponent,
    PageTitleWithTabsComponent,
    PanelComponent,
    SideNavTemplateComponent,
    TableOfContentComponent,
    ObserveVisibilityDirective,
  ],
  imports: [AngularSvgIconModule, CommonModule, ClipboardModule, MatTabsModule, RouterModule],
  providers: [DatePipe],
})
export class SharedModule {}

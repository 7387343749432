import { BrandNavSchema, NavSchema } from '../interface/layout.interface';

export const DEFAULT_BRAND_ROUTE = '/design-system/woolworths';

export const DEFAULT_SCHEMAS: NavSchema[] = [
  {
    label: '',
    url: '/',
  },
];

export const NAV_SCHEMA_ABOUT: NavSchema[] = [
  {
    iconName: 'overview.svg',
    label: 'Overview',
    url: '/about/overview',
  },
  {
    iconName: 'page-with-tick.svg',
    label: 'Roadmap',
    url: '/about/roadmap',
    isDisabled: true,
  },
  {
    iconName: 'whats-new-with-tick.svg',
    label: `What's new`,
    url: '/about/whats-new',
    isDisabled: true,
  },
];

export const NAV_SCHEMA_BRANDS: BrandNavSchema[] = [
  {
    brand: 'woolworths',
    label: 'Woolworths',
    url: '/design-system/woolworths',
  },
  {
    brand: 'bigw',
    label: 'BIG W',
    url: '/design-system/bigw',
  },
  {
    brand: 'everyday',
    label: 'Everyday',
    url: '/design-system/everyday',
  },
];

export const NAV_SCHEMA_RESOURCES: NavSchema[] = [
  {
    iconName: 'overview.svg',
    label: 'Overview',
    url: '/resources/overview',
  },
  {
    iconName: 'design-principles.svg',
    label: 'Design principles',
    url: '/resources/design-principles',
  },
  {
    iconName: 'accessibility.svg',
    label: 'Accessibility',
    url: '/resources/accessibility',
  },
  {
    iconName: 'curly-brace.svg',
    label: 'Design Tokens',
    url: '/resources/design-tokens',
  },
  {
    iconName: 'content.svg',
    label: 'Content',
    url: '/resources/content-guides',
    isDisabled: true,
  },
  {
    iconName: 'design.svg',
    label: 'Design',
    url: '/resources/design',
    isDisabled: true,
  },
  {
    iconName: 'code.svg',
    label: 'Develop',
    url: '/resources/build',
    isDisabled: true,
  },
  {
    iconName: 'tutorials.svg',
    label: 'Tutorials & Videos',
    url: '/tutorials',
    isDisabled: true,
  },
];

export const NAV_SCHEMA_HEADER: NavSchema[] = [
  {
    label: 'Getting started',
    url: '/getting-started',
  },
  {
    label: 'Resources',
    url: '/resources',
  },
  {
    label: 'Design system',
    url: '/design-system',
  },
  {
    label: 'About',
    url: '/about',
  },
];

export const NAV_SCHEMA_GETTING_STARTED: NavSchema[] = [
  {
    iconName: 'overview.svg',
    label: 'Overview',
    url: '/getting-started/overview',
  },
  {
    iconName: 'design.svg',
    label: 'Designers',
    url: '/getting-started/designers',
  },
  {
    iconName: 'code.svg',
    label: 'Developers',
    url: '/getting-started/developers',
  },
  {
    iconName: 'design-principles.svg',
    label: 'Contributing',
    url: '/getting-started/contributing',
  },
];

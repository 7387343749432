import { Component, Input } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';

import { NavToggleService } from '../../shared/services/nav-toggle/nav-toggle.service';
import { BRAND_LOGO } from './side-nav-toggle.component.constants';

@Component({
  selector: 'ds-side-nav-toggle',
  templateUrl: './side-nav-toggle.component.html',
  styleUrls: ['./side-nav-toggle.component.scss'],
})
export class SideNavToggleComponent {
  @Input() brand: BRAND | null = null;

  brandLogo = BRAND_LOGO;

  constructor(private navToggleService: NavToggleService) {}

  toggleSideNav() {
    this.navToggleService.toggle();
  }
}

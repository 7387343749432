import { Component, Input } from '@angular/core';

import { DEFAULT_CONFIG } from './panel.component.constants';

@Component({
  selector: 'ds-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input() description = DEFAULT_CONFIG.description;
  @Input() heading = DEFAULT_CONFIG.heading;
  @Input() imgUrl = DEFAULT_CONFIG.imgUrl;
  @Input() isImgLast = DEFAULT_CONFIG.isImgLast;
}

<article class="layout-card" [ngClass]="{ 'img-last': imgLast }">
  <div class="content">
    <h3 *ngIf="heading && isH3" class="title-lg spacer">{{ heading }}</h3>

    <h4 *ngIf="heading && !isH3" class="title-lg spacer">{{ heading }}</h4>

    <p *ngIf="description">{{ description }}</p>

    <ng-content></ng-content>
  </div>

  <ds-image *ngIf="img" [img]="img"></ds-image>
</article>

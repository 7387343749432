import { Component, Input } from '@angular/core';
import { BREAKPOINTS } from '@Shared/constants/constants';
import { LazyImage } from '@Shared/interface/interface';

@Component({
  selector: 'ds-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() img: LazyImage | null = null;

  breakpoint = `(min-width: ${BREAKPOINTS.sm})`;
}

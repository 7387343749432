import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent {
  @Input() altText = '';
  @Input() imgFilename = '';
  @Input() figcaption = '';
}

<figure class="u-responsive-spacer">
  <div class="image">
    <img
      src="./resources/images/banner/{{ imgFilename }}.svg"
      alt="{{ altText }}"
      width="343"
      height="310"
      loading="lazy" />
  </div>

  <figcaption>{{ figcaption }}</figcaption>
</figure>

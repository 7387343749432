<header role="banner" #header [ngClass]="{ 'show-header': showHeader }">
  <div class="container">
    <div class="inner">
      <a class="logo" routerLink="/" routerLinkActive="active" aria-label="Core Design System">
        <svg-icon class="svg" src="./resources/logos/core-logo-full-size.svg"></svg-icon>
      </a>
      <nav>
        <ul>
          <li *ngFor="let schema of navSchema">
            <a *ngIf="!schema.isDisabled" [routerLink]="schema.url" routerLinkActive="active">
              {{ schema.label }}
            </a>
            <div *ngIf="schema.isDisabled" class="faux-link">
              {{ schema.label }}
              <span>Coming soon</span>
            </div>
          </li>
        </ul>
        <ds-side-nav-toggle *ngIf="enableSideNavToggle" [brand]="brand"></ds-side-nav-toggle>
      </nav>
    </div>
  </div>
</header>

import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';

import { NAV_SCHEMA_HEADER } from '../shared/constants/nav-schema.constants';
import { NavSchema } from '../shared/interface/layout.interface';

@Component({
  selector: 'ds-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('header') header!: ElementRef;
  @Input() brand: BRAND | null = null;
  @Input() enableSideNavToggle = false;
  @Output() navToggleUpdated = new EventEmitter();

  navSchema: NavSchema[] = NAV_SCHEMA_HEADER;
  destroySub = new Subject<void>();
  showHeader = true;
  showSideNavToggle = false;

  constructor(private viewportScroller: ViewportScroller) {}

  ngAfterViewInit(): void {
    this.setHeaderSubscription();
  }

  ngOnDestroy(): void {
    this.destroySub.next();
  }

  getHeaderHeight(): number {
    return (this.header.nativeElement as HTMLElement).offsetHeight;
  }

  setHeaderSubscription(): void {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroySub), debounceTime(50))
      .subscribe(() => {
        this.showHeader = this.viewportScroller.getScrollPosition()[1] <= this.getHeaderHeight();
      });
  }
}

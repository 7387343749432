import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() description = '';
  @Input() heading!: string;
  @Input() imgFilename = '';
  @Input() routerUrl = '';
}

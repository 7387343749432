<figure>
  <picture>
    <source media="{{ breakpoint }}" srcset="{{ img?.imgPathDesktop }}" />
    <img
      [attr.src]="img?.imgPath"
      [attr.alt]="img?.alt"
      [attr.height]="img?.dimension?.height"
      [attr.width]="img?.dimension?.width"
      [attr.loading]="img?.isLazyLoad" />
  </picture>

  <figcaption *ngIf="img?.figcaption || img?.figcaptionHeading" class="u-type-size-sm">
    <strong class="u-type-weight-bold">{{ img?.figcaptionHeading }}</strong>
    <div>{{ img?.figcaption }}</div>
  </figcaption>
</figure>

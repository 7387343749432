<h2 class="headline-md spacer spacer-size-2xl">Follow foundations guidelines</h2>

<div class="grid spacer spacer-size-6xl">
  <div class="grid-item" style="--cols-sm: 6">
    <ds-card
      description="Complete colour system to help you apply colours when designing and building products anyone can use."
      heading="Colours"
      imgFilename="getting-started-designer-following-foundation-guidelines-colour.svg"
      routerUrl="/design-system/woolworths/foundation/colours/overview">
    </ds-card>
  </div>

  <div class="grid-item" style="--cols-sm: 6">
    <ds-card
      description="Make quicker decisions when applying type scales to any product experience with our Typography usage guidelines and code."
      heading="Typography"
      imgFilename="getting-started-designer-following-foundation-guidelines-typography.svg"
      routerUrl="/design-system/woolworths/foundation/typography/overview">
    </ds-card>
  </div>

  <div class="grid-item" style="--cols-sm: 6">
    <ds-card
      description="The single source of truth for the grid to be used across all platforms and product experiences."
      heading="Grid"
      imgFilename="getting-started-designer-following-foundation-guidelines-grid.svg"
      routerUrl="/design-system/woolworths/foundation/grids/overview">
    </ds-card>
  </div>

  <div class="grid-item" style="--cols-sm: 6">
    <ds-card
      description="Use this spacing system to apply best practice when building and designing product experiences."
      heading="Spacing"
      imgFilename="getting-started-designer-following-foundation-guidelines-spacing.svg"
      routerUrl="/design-system/woolworths/foundation/spacing/overview">
    </ds-card>
  </div>
</div>

<div class="collapsible">
  <button
    [attr.aria-label]="label"
    [attr.aria-expanded]="expanded"
    [attr.aria-controls]="ariaControlName"
    [disabled]="isDisabled"
    (click)="toggleCollapsible()">
    <svg-icon
      *ngIf="expanded"
      src="./resources/icons/outline-minus.svg"
      [svgClass]="'svg'"></svg-icon>

    <svg-icon
      *ngIf="!expanded"
      src="./resources/icons/outline-plus.svg"
      [svgClass]="'svg'"></svg-icon>
  </button>

  <a
    aria-hidden="true"
    tabindex="-1"
    [class]="brand"
    [routerLink]="url"
    routerLinkActive="is-active">
    {{ label }}
  </a>
</div>

<div *ngIf="expanded" [attr.id]="ariaControlName">
  <ng-content></ng-content>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    data: { title: 'Home page', brand: null },
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'getting-started',
    data: { title: 'Getting started', hasSideNav: true, brand: null },
    loadChildren: () =>
      import('./getting-started/getting-started.module').then((m) => m.GettingStartedModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'resources',
    data: { title: 'Resources', hasSideNav: true, brand: null },
    loadChildren: () => import('./resources/resources.module').then((m) => m.ResourcesModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'design-system',
    data: { title: 'Design system', hasSideNav: true },
    loadChildren: () =>
      import('./design-system/design-system.module').then((m) => m.DesignSystemModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'about',
    data: { title: 'About', hasSideNav: true, brand: null },
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="footer">
  <div class="container">
    <aside role="complementary" class="complementary">
      <div class="intro">
        <svg-icon
          class="logo"
          role="img"
          aria-label="Core design system"
          src="resources/logos/core-logo-bw-full-size.svg"></svg-icon>

        <p>{{ complementaryIntro }}</p>

        <p>Last updated <time datetime="2024-01-11">11 Jan 2024</time></p>
      </div>

      <div class="intro">
        <h2 class="headline-sm">Have questions?</h2>

        <p>
          Email us at
          <a href="mailto:{{ emailAddress }}">{{ emailAddress }}</a>
          or open an issue on
          <ds-link
            [isExternalLink]="true"
            label="Github"
            url="https://github.com/woolworthslimited/core/issues"></ds-link
          >.
        </p>
      </div>
    </aside>
  </div>

  <footer role="contentinfo" class="content-info">
    <div class="container">
      <ul class="inner">
        <li>
          <small>© Woolworths Ltd 1997-{{ currentYear }} - All Rights Reserved.</small>
        </li>
        <li>
          <ds-link
            [isExternalLink]="true"
            label="Terms of use"
            url="https://docs.google.com/document/d/11yCF8oqbaFZ7q7sYSQoPwN0YMKgCeFqJqhe-GtUEoTQ/"></ds-link>
        </li>
        <li>
          <ds-link
            [isExternalLink]="true"
            label="Privacy policy"
            url="https://www.woolworthsgroup.com.au/au/en/privacy/policy-documents/privacy-policy.html"></ds-link>
        </li>
      </ul>
    </div>
  </footer>
</div>

<div class="page">
  <div class="grid">
    <div class="grid-item grid-item-offset" style="--cols-lg: 2; --col-start-lg: 9">
      <div class="toc">
        <ng-content select="[template-slot-toc]"></ng-content>
      </div>
    </div>

    <div class="grid-item" style="--cols-lg: 8">
      <ng-content select="[template-slot-content]"></ng-content>
    </div>
  </div>
</div>

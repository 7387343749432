import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavToggleService {
  navToggledSubject = new BehaviorSubject<boolean>(false);
  navToggleObservable = this.navToggledSubject.asObservable();
  toggled = false;

  reset() {
    this.toggled = false;
    this.navToggledSubject.next(this.toggled);
  }

  toggle() {
    this.toggled = !this.toggled;
    this.navToggledSubject.next(this.toggled);
  }
}

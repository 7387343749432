<section>
  <h2 class="headline-md spacer spacer-size-2xl">Getting support</h2>

  <p class="spacer spacer-size-2xl">
    If you experience any issues while using any of Core’s tools, email
    <a href="mailto:core@woolworths.com.au" target="_blank" rel="noopener noreferrer">
      core@woolworths.com.au
    </a>
    or reach out to us on Slack via
    <a
      href="https://woolies-x.slack.com/archives/C03TKPYUNDU"
      target="_blank"
      rel="noopener noreferrer">
      #guild-core-design-system </a
    >.
  </p>
</section>

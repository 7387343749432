import { Link } from '@Shared/layout/shared/interface/layout.interface';

export const FOOTER_LINKS: Link[] = [
  { label: 'Privacy at Woolworths', url: '#' },
  { label: 'Terms & Conditions', url: '#' },
  { label: 'Accessibility', url: '#' },
];

export const COMPLEMENTARY_INTRO =
  'Core is our single source of truth, used to build inclusive, cohesive, and high-quality customer experience across our entire digital landscape.';

import { Component, Input } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';

import { ToCNavItem } from './table-of-content.interfaces';

@Component({
  selector: 'ds-table-of-content',
  templateUrl: './table-of-content.component.html',
  styleUrls: ['./table-of-content.component.scss'],
})
export class TableOfContentComponent {
  @Input() brand: BRAND | null = null;
  @Input() navItems: ToCNavItem[] = [];

  navItemsWithSingleActiveElement(): ToCNavItem[] {
    let foundActive = false;

    return this.navItems.map(({ label, visible, anchor }) => {
      if (!foundActive && visible) {
        foundActive = true;

        return { label, visible: true, anchor };
      }

      return { label, visible: false, anchor };
    });
  }

  // TODO: Refactor into a service? Also used in NavList.
  getBrandColour() {
    return this.brand
      ? getComputedStyle(document.documentElement).getPropertyValue(`--${this.brand}`)
      : null;
  }
}

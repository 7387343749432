import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent {
  @Input() text = '';
}

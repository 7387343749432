import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input() ariaLabel = '';
  @Input() isDisabled = false;
  @Input() isExternalLink? = false;
  @Input() label? = '';
  @Input() url = '';
}

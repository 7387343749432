import { Component, Input, OnInit } from '@angular/core';
import { BRAND } from '@Shared/constants/constants';
import { kebabCase } from 'lodash-es';

@Component({
  selector: 'ds-nav-collapsible',
  templateUrl: './nav-collapsible.component.html',
  styleUrls: ['./nav-collapsible.component.scss'],
})
export class NavCollapsibleComponent implements OnInit {
  @Input() brand?: BRAND.BIGW | BRAND.EVERYDAY | BRAND.WOOLWORTHS;
  @Input() label!: string;
  @Input() url?: string;
  @Input() isDisabled?: boolean;

  expanded = false;
  ariaControlName = '';

  ngOnInit() {
    this.generateAriaControlName();
  }

  toggleCollapsible(): void {
    this.expanded = !this.expanded;
  }

  generateAriaControlName(): void {
    const controlName = this.label?.replace(/\W/g, ' ');
    this.ariaControlName = kebabCase(controlName);
  }
}

<div class="panel" [ngClass]="{ 'img-last': isImgLast }">
  <div class="header">
    <img [src]="imgUrl" alt="" width="290" height="190" />

    <div>
      <h2 class="headline-md">{{ heading }}</h2>

      <p>{{ description }}</p>
    </div>
  </div>

  <div #contentEl class="content">
    <ng-content></ng-content>
  </div>
</div>

import { Component } from '@angular/core';
import { EMAIL_ADDRESS } from '@Shared/constants/constants';
import { Link } from '@Shared/layout/shared/interface/layout.interface';

import { COMPLEMENTARY_INTRO, FOOTER_LINKS } from './footer.constants';

@Component({
  selector: 'ds-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  complementaryIntro: string = COMPLEMENTARY_INTRO;
  currentYear: number = new Date().getFullYear();
  emailAddress = EMAIL_ADDRESS;
  footerLinks: Link[] = FOOTER_LINKS;
}
